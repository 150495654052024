import { AppModalProps } from 'views/AppModal/AppModal.model';
import { PDrawerModalProps } from 'views/PDrawerModal/PDrawerModal.model';
import { PModalProps } from 'views/PModal/PModal.model';
import { PToastProps } from 'views/PToast/PToast.model';
import * as actions from '../actions/ui';

const initialState: State = {
  modal: null,
  appModal: null,
  loadingSpinner: false,
  autoLoginLoadingSpinner: false,
  toast: null,
  drawerModal: null,
};

export const reducer = (state: State = initialState, action: actions.UITypes): State => {
  switch (action.type) {
    case actions.UIActions.ShowModal:
      return { ...state, modal: action.payload };
    case actions.UIActions.HideModal:
      return { ...state, modal: null };
    case actions.UIActions.ShowAppModal:
      return { ...state, appModal: action.payload };
    case actions.UIActions.HideAppModal:
      return { ...state, appModal: null };
    case actions.UIActions.ShowDrawerModal:
      return { ...state, drawerModal: action.payload };
    case actions.UIActions.HideDrawerModal:
      return { ...state, drawerModal: null };
    case actions.UIActions.ShowSpinner:
      return { ...state, loadingSpinner: action.payload.state };
    case actions.UIActions.ShowAutoLoginSpinner:
      return { ...state, autoLoginLoadingSpinner: action.payload.state };
    case actions.UIActions.showErrorToast:
      return { ...state, toast: action.payload };
    case actions.UIActions.showSuccessToast:
      return { ...state, toast: action.payload };
    case actions.UIActions.hideToast:
      return { ...state, toast: null };
    default:
      return state;
  }
};

export type State = {
  modal: PModalProps | null;
  appModal: AppModalProps | null;
  drawerModal: PDrawerModalProps | null;
  loadingSpinner: number | boolean;
  autoLoginLoadingSpinner: number | boolean;
  toast: PToastProps | null;
};
