import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import PToast from 'views/PToast/PToast';
import Loading from 'views/PLoading/PLoading';
import AppModal from 'views/AppModal/AppModal';

import classes from './App.module.scss';
import { AppRouteType, IAppRoute } from './App.model';
import { PToastProps } from 'views/PToast/PToast.model';
import { AppModalProps } from 'views/AppModal/AppModal.model';
import * as uiActions from './store/actions/ui';

type Props = {
  loadingSpinner: number | boolean;
  autoLoginLoadingSpinner: number | boolean;
  routesList: IAppRoute[];
  toast: PToastProps | null;
  showToast: boolean;
  appModal: AppModalProps | null;
  onHideToast: () => void;
  onToastShown: () => void;
  hideAppModal: () => uiActions.HideAppModal;
};

const AppView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  const generateRoutes = (routes: IAppRoute[]) => {
    return routes.map((route: IAppRoute) => {
      const Component: React.FC | undefined = route['component'];
      const jsx: JSX.Element | undefined = route['jsx'];

      return (
        <Route
          key={route.path}
          path={route.path}
          element={
            route.type === AppRouteType.Fallback ? (
              <Navigate to={route.to}></Navigate>
            ) : (
              <React.Fragment>{Component ? <Component></Component> : jsx}</React.Fragment>
            )
          }
        >
          {route.children ? generateRoutes(route.children) : null}
        </Route>
      );
    });
  };

  return (
    <React.Fragment>
      <Loading
        show={!!props.autoLoginLoadingSpinner}
        percent={typeof props.autoLoginLoadingSpinner === 'number' ? props.autoLoginLoadingSpinner : null}
        autoLogin
      ></Loading>
      <Loading show={!!props.loadingSpinner} percent={typeof props.loadingSpinner === 'number' ? props.loadingSpinner : null}></Loading>
      <React.Suspense
        fallback={
          <div className={classes['loading']}>
            <div className="sk-spinner sk-spinner-pulse"></div>
          </div>
        }
      >
        {props.appModal ? <AppModal {...props.appModal} onClose={props.hideAppModal}></AppModal> : null}
        <Routes>{generateRoutes(props.routesList)}</Routes>
        <CSSTransition
          in={props.showToast}
          timeout={{ exit: 5000 }}
          classNames={{
            exitActive: classes['toast--close'],
          }}
          unmountOnExit
          onEntered={props.onToastShown}
          onExited={props.onHideToast}
        >
          <PToast className={classes['toast']} proggressBarClassName={classes['toastProggressBar']} {...props.toast}></PToast>
        </CSSTransition>
      </React.Suspense>
    </React.Fragment>
  );
};

AppView.displayName = 'AppView';
AppView.defaultProps = {};

export default AppView;
