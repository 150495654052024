import React from 'react';
import BounceLoader from 'react-spinners/BounceLoader';

import classes from './PSpinner.module.scss';
import { SpinnerSize } from './PSpinner.model';
import colorsVariables from '../../styles/colorsVariables.module.scss';

type Props = {
  size: SpinnerSize;
  windowWidth: number;
  className?: string;
  dataTestid?: string;
};

const PSpinnerView: React.FC<Props> = (props: React.PropsWithChildren<Props>) => {
  return (
    <div className={`${classes['container']} ${props.className || ''}`.trim()}>
      <BounceLoader
        data-testid={props.dataTestid}
        size={
          props.size === SpinnerSize.Small
            ? props.windowWidth < 1441
              ? '15px'
              : props.windowWidth > 1921
              ? '19px'
              : '1vw'
            : props.size === SpinnerSize.Medium
            ? props.windowWidth < 1441
              ? '80px'
              : props.windowWidth > 1921
              ? '115px'
              : '6vw'
            : props.windowWidth < 1441
            ? '100px'
            : props.windowWidth > 1921
            ? '134px'
            : '7vw'
        }
        color={colorsVariables.spinner}
      ></BounceLoader>
    </div>
  );
};

PSpinnerView.displayName = 'PSpinnerView';
PSpinnerView.defaultProps = {};

export default PSpinnerView;
