/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';

import AppView from './App.view';

import * as uiActions from './store/actions/ui';
import * as authActions from './store/actions/auth';
import useRoutes, { Route } from './utils/hooks/use-routes';
import { serverAxios } from './utils/http';
import { AppState } from './models/app-store';
import { IAppRoute, AppRouteType } from './App.model';
import { GuardData } from './routes/guards/guard.model';
import { PToastProps } from 'views/PToast/PToast.model';
import { ErrorResponse, ILoginAccessTokenResponse } from 'models/response';
import { AppModalProps } from 'views/AppModal/AppModal.model';
import { SessionStorage } from 'utils/enums/storage';
import { IUser } from 'models/user';
import { IPermission } from 'models/permission';
import { ResponseStatusCode } from 'utils/enums/http-response';

const App: React.FC = () => {
  const { t } = useTranslation();

  const [showToastState, setShowToastState] = useState<boolean>(false);

  const dispatch = useDispatch();

  const routes: Route[] = useRoutes();
  const store: AppState = useSelector((state: AppState) => state);
  const isAuthenticated: boolean = useSelector((state: AppState) => !!state.auth.user);
  const loadingSpinner: boolean | number = useSelector((state: AppState) => state.ui.loadingSpinner);
  const autoLoginLoadingSpinner: boolean | number = useSelector((state: AppState) => state.ui.autoLoginLoadingSpinner);
  const token: string | null = useSelector((state: AppState) => state.auth.token);
  const toast: PToastProps | null = useSelector((state: AppState) => state.ui.toast);
  const appModal: AppModalProps | null = useSelector((state: AppState) => state.ui.appModal);

  useEffect(() => {
    dispatch(authActions.autoLogin());
  }, [dispatch]);

  // Add authorization Bearer token to requests headers
  useEffect(() => {
    if (token) {
      const tokenInterceptor: number = serverAxios.interceptors.request.use((request: AxiosRequestConfig) => {
        if (request.url?.includes('/access-token')) {
          return { ...request, headers: { ...request.headers } };
        }

        return { ...request, headers: { ...request.headers, Authorization: `Bearer ${token}` } };
      });

      return () => {
        serverAxios.interceptors.request.eject(tokenInterceptor);
      };
    }
  }, [token]);

  // Add logout when status code 401 sent back from the server
  useEffect(() => {
    if (isAuthenticated) {
      const logoutInterceptor: number = serverAxios.interceptors.response.use(
        (response: AxiosResponse): AxiosResponse => response,
        (error: ErrorResponse) => {
          // If we get 401 in the portal, we will try to get a new token. if we fail - we logout
          if (error.status === ResponseStatusCode.InvalidTokenOrSession) {
            const refreshToken: string | null = sessionStorage.getItem(SessionStorage.RefreshToken);
            const currentUser: string = sessionStorage.getItem(SessionStorage.JWT) || '';
            const user: IUser = JSON.parse(window.atob(currentUser.split('.')[1]));

            serverAxios
              .get(`/access-token?oid=${user.oid}`, {
                headers: { Authorization: `Bearer ${refreshToken}` },
              })
              .then((tokenResponse: AxiosResponse<ILoginAccessTokenResponse>) => {
                const newJWT: string = tokenResponse.data.token;
                const newUser: IUser = JSON.parse(window.atob(newJWT.split('.')[1]));
                const userPermissions: IPermission[] = user.scope;
                const now: number = moment().unix();
                const exp: number = newUser.exp;
                const duration: moment.Duration = moment.duration(moment.unix(exp).diff(moment.unix(now)));
                const getMinutes: number = duration.asMinutes();

                sessionStorage.setItem(SessionStorage.Exp, getMinutes.toString());
                dispatch(authActions.setToken(newJWT));
                dispatch(authActions.setPermissions(userPermissions));
                sessionStorage.setItem(SessionStorage.JWT, newJWT);
              })
              .catch(() => {
                dispatch(
                  uiActions.showErrorToast({
                    message: t('pages.common.invalidTokenOrSessionToast.message'),
                  }),
                );

                dispatch(authActions.logout());
              });
          }

          // All over the app, we check if HTTP response error !== 429
          // We must do this check, since we handle errors diffrenetly in the app
          // If it's 429 error, we have handle it here
          if (error.status === ResponseStatusCode.RateLimit) {
            dispatch(
              uiActions.showErrorToast({
                message: t('pages.common.tooManyRequests.message'),
              }),
            );
          }

          throw error;
        },
      );

      return () => {
        serverAxios.interceptors.response.eject(logoutInterceptor);
      };
    }
  }, [isAuthenticated, dispatch]);

  const prepareRouteList: any = (routes: Route[]) => {
    const guardData: GuardData = {
      store: store,
    };

    return routes.reduce((acc: IAppRoute[], route: Route) => {
      if (route.guard && !route.guard.every((guard) => guard(guardData))) {
        if (route.fallback) {
          const contentRoute: IAppRoute = {
            type: AppRouteType.Fallback,
            path: route.path,
            to: route.fallback,
            children: route.children ? prepareRouteList(route.children) : null,
          };

          return [...acc, contentRoute];
        }

        return acc;
      }

      const component: React.FC | undefined = route['component'];
      const jsx: JSX.Element = route['jsx'];

      const contentRoute: IAppRoute = {
        type: AppRouteType.Page,
        path: route.path,
        component,
        jsx,
        children: route.children ? prepareRouteList(route.children) : null,
      };

      return [...acc, contentRoute];
    }, []);
  };

  useEffect(() => {
    setShowToastState(() => !!toast);
  }, [toast, setShowToastState]);

  const hideAppModal = () => {
    if (appModal?.onClose) {
      appModal?.onClose();
    }
    return dispatch(uiActions.hideAppModal());
  };

  return (
    <AppView
      loadingSpinner={loadingSpinner}
      autoLoginLoadingSpinner={autoLoginLoadingSpinner}
      toast={toast}
      showToast={showToastState}
      routesList={prepareRouteList(routes)}
      onHideToast={() => dispatch(uiActions.hideToast())}
      onToastShown={() => setShowToastState(() => false)}
      appModal={appModal}
      hideAppModal={hideAppModal}
    ></AppView>
  );
};

App.displayName = 'App';

export default App;
