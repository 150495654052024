export enum FeaturesFlags {
  AccountSettings = 'account-settings',
  Configurations = 'configurations',
  Settings = 'settings',
  Home = 'home',
  Readiness = 'readiness',
  Sensors = 'sensors',
  Incidents = 'incidents',
  Reports = 'reports',
  SensorsDashboard = 'sensors-dashboard',
  IncidentsActive = 'incidents-active',
  IncidentsHistory = 'incidents-history',
  Resources = 'resources',
  Secrets = 'secrets',
  UsersSettings = 'user-settings',
  Welcome = 'welcome',
  AdminPage = 'admin-page',
  ConditionalAccess = 'conditional-access',
  Prospect = 'prospect',
  FileSharing = 'file-sharing',
  FileSharingStatus = 'file-sharing-status',
  FileSharingUsage = 'file-sharing-usage',
  Cve = 'cve-2024-3094',
  Audit = 'audit',
  Findings = 'findings',
  Usage = 'usage',
  Health = 'health',
  CustomerFile = 'customer-file',
}
