import { ToastType } from 'utils/enums/toast-types';
import { AppModalProps } from 'views/AppModal/AppModal.model';
import { PDrawerModalProps } from 'views/PDrawerModal/PDrawerModal.model';
import { PModalProps } from 'views/PModal/PModal.model';
import { PToastProps } from 'views/PToast/PToast.model';

export enum UIActions {
  SetTheme = '[UI] Set Change Theme',
  ShowSpinner = '[UI] Show Spinner',
  ShowAutoLoginSpinner = '[UI] Show Auto Login Spinner',
  ShowModal = '[UI] Show Modal',
  HideModal = '[UI] Hide Modal',
  ShowAppModal = '[UI] Show App Modal',
  HideAppModal = '[UI] Hide App Modal',
  showErrorToast = '[UI] Show Error Toast',
  showSuccessToast = '[UI] Show Success Toast',
  hideToast = '[UI] Hide Toast',
  ShowDrawerModal = '[UI] Show Drawer Modal',
  HideDrawerModal = '[UI] Hide Drawer Modal',
}

export type ShowModal = {
  type: UIActions.ShowModal;
  payload: PModalProps | null;
};

export type HideModal = {
  type: UIActions.HideModal;
};

export type ShowAppModal = {
  type: UIActions.ShowAppModal;
  payload: PModalProps | null;
};

export type HideAppModal = {
  type: UIActions.HideAppModal;
};

export type ShowDrawerModal = {
  type: UIActions.ShowDrawerModal;
  payload: PDrawerModalProps | null;
};

export type HideDrawerModal = {
  type: UIActions.HideDrawerModal;
};

export type ShowSpinner = {
  type: UIActions.ShowSpinner;
  payload: { state: number | boolean };
};

export type ShowAutoLoginSpinner = {
  type: UIActions.ShowAutoLoginSpinner;
  payload: { state: number | boolean };
};

export type ShowErrorToast = {
  type: UIActions.showErrorToast;
  payload: PToastProps | null;
};

export type ShowSuccessToast = {
  type: UIActions.showSuccessToast;
  payload: PToastProps | null;
};

export type HideToast = {
  type: UIActions.hideToast;
};

// Action creators

export const showModal = (modal: PModalProps): ShowModal => {
  return {
    type: UIActions.ShowModal,
    payload: { ...modal, show: true },
  };
};

export const hideModal = (): HideModal => {
  return {
    type: UIActions.HideModal,
  };
};

export const showAppModal = (modal: AppModalProps): ShowAppModal => {
  return {
    type: UIActions.ShowAppModal,
    payload: { ...modal, show: true },
  };
};

export const hideAppModal = (): HideAppModal => {
  return {
    type: UIActions.HideAppModal,
  };
};

export const showDrawerModal = (drawerModal: PDrawerModalProps): ShowDrawerModal => {
  return {
    type: UIActions.ShowDrawerModal,
    payload: { ...drawerModal, show: true },
  };
};

export const hideDrawerModal = (): HideDrawerModal => {
  return {
    type: UIActions.HideDrawerModal,
  };
};

export const showSpinner = (state: boolean | number): ShowSpinner => {
  return {
    type: UIActions.ShowSpinner,
    payload: { state },
  };
};

export const showAutoLoginSpinner = (state: boolean | number): ShowAutoLoginSpinner => {
  return {
    type: UIActions.ShowAutoLoginSpinner,
    payload: { state },
  };
};

export const showErrorToast = (toast: PToastProps): ShowErrorToast => {
  return {
    type: UIActions.showErrorToast,
    payload: { ...toast, type: ToastType.Error },
  };
};

export const showSuccessToast = (toast: PToastProps): ShowSuccessToast => {
  return {
    type: UIActions.showSuccessToast,
    payload: { ...toast, type: ToastType.Success },
  };
};

export const hideToast = (): HideToast => {
  return {
    type: UIActions.hideToast,
  };
};

// Types

export type UITypes =
  | ShowModal
  | HideModal
  | ShowAppModal
  | HideAppModal
  | ShowDrawerModal
  | HideDrawerModal
  | ShowSpinner
  | ShowAutoLoginSpinner
  | ShowErrorToast
  | ShowSuccessToast
  | HideToast;
