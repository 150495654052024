import { SessionStorage } from './enums/storage';

const load = async <T>(key: SessionStorage): Promise<T> => {
  let data: unknown;
  const item: string | null = sessionStorage.getItem(key);

  try {
    data = !item ? item : JSON.parse(item);
  } catch (error) {
    data = item;
  }

  return (await data) as T;
};

const save = (key: string, state: Record<string, unknown> | string): void => {
  if (typeof state === 'object') {
    const serializedState = JSON.stringify(state);

    sessionStorage.setItem(key, serializedState);

    return;
  }

  sessionStorage.setItem(key, state);
};

const sess = { load, save };

export default sess;
